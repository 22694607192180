export const calculateSeeingIndex = (temperature, dewPoint, windSpeed180m, humidity) => {
    // Ajustements des poids pour générer une plus grande variation
    const tempWeight = 0.3; // Poids modéré pour la température
    const windWeight = 0.5; // Poids élevé pour l'impact du vent
    const humidityWeight = 0.1; // Poids réduit pour l'humidité
    const dewPointWeight = 0.1; // Poids réduit pour la différence température-point de rosée

    // Calcul de la différence température-point de rosée
    const tempDifference = Math.abs(temperature - dewPoint);

    // Ajustement des facteurs pour élargir les plages
    const tempFactor = Math.max(0.1, Math.min(1, (15 - tempDifference) / 15));  
    const windFactor = Math.max(0.1, Math.min(1, 1 - windSpeed180m / 25)); 
    const humidityFactor = Math.max(0.1, Math.min(1, 1 - humidity / 100)); 
    const dewPointFactor = Math.max(0.1, Math.min(1, (10 - tempDifference) / 10));  

    // Calcul de l'indice de seeing
    const weightedIndex = (
        tempWeight * tempFactor +
        windWeight * windFactor +
        humidityWeight * humidityFactor +
        dewPointWeight * dewPointFactor
    );

    return Math.round(Math.max(1, weightedIndex * 5)); // Plage : 1 à 5
};
