import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Assurez-vous que 'auth' est correctement exporté depuis votre fichier firebase.js
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { app } from '../firebase'; // Vérifiez que 'app' est bien exporté depuis votre fichier firebase.js
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LocationComponent = () => {
  const [user, setUser] = useState(null);
  const [city, setCity] = useState(localStorage.getItem('cityName'));
  const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')));
  const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')));
  const [favoriteCities, setFavoriteCities] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null); // État pour stocker la date de mise à jour
  const location = useLocation(); // Accède à l'objet location

  const navigate = useNavigate();
  const { t } = useTranslation();

  const titleKey = location.pathname === '/tonightforecast' ? 'titletab1' : 'titletab3';



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const favoritesDocRef = doc(getFirestore(), `users/${user.uid}/ouranos/favorites`);
        getDoc(favoritesDocRef)
          .then((docSnap) => {
            if (docSnap.exists()) {
              const favoritesData = docSnap.data();
              const favoritesList = Object.values(favoritesData); // Assurez-vous que les données sont structurées comme attendu
              setFavorites(favoritesList);
              const favoriteCitiesList = favoritesList.map((favorite) => favorite.city);
              setFavoriteCities(favoriteCitiesList);
            }
          })
          .catch((error) => {
            console.error("Erreur lors de la récupération des favoris :", error);
          });
      }
    });

    // Nettoyer l'abonnement à onAuthStateChanged lors du démontage du composant
    return () => unsubscribe();
  }, []);


  useEffect(() => {

    const newCityName = localStorage.getItem('cityName');
    const newLatitude = parseFloat(localStorage.getItem('latitude'));
    const newLongitude = parseFloat(localStorage.getItem('longitude'));

    if (city !== newCityName) {
      setCity(newCityName);
    }

    if (latitude !== newLatitude) {
      setLatitude(newLatitude);
    }

    if (longitude !== newLongitude) {
      setLongitude(newLongitude);
    }

    // Récupérer et afficher le timestamp de la dernière mise à jour
    const cacheTimestamp = localStorage.getItem('openMeteoCacheTimestamp');
    if (cacheTimestamp) {
      // Détecter la langue du navigateur
      const browserLanguage = navigator.language || navigator.userLanguage;

      // Formater l'heure selon la langue du navigateur
      const formattedTime = new Date(parseInt(cacheTimestamp)).toLocaleTimeString(browserLanguage, {
        hour: '2-digit',   // Afficher l'heure sur 2 chiffres
        minute: '2-digit'  // Afficher les minutes sur 2 chiffres
      });

      setLastUpdated(formattedTime); // Stocke l'heure formatée sans les secondes
    }


  }, [city, latitude, longitude]);


  const handleStarClick = () => {
    const cityId = city.replace(/\s+/g, '_').toLowerCase(); // Utiliser le nom de la ville comme ID en supprimant les espaces
    const newFavorite = {
      city: city,
      latitude: latitude,
      longitude: longitude,
    };

    if (user) {
      const favoritesDocRef = doc(getFirestore(app), `users/${user.uid}/ouranos/favorites`);

      getDoc(favoritesDocRef).then((docSnap) => {
        const favoritesData = docSnap.exists() ? docSnap.data() : {};

        if (favoritesData[cityId]) {
          // Si la ville est déjà dans les favoris, la supprimer
          delete favoritesData[cityId];
        } else {
          // Sinon, ajouter la ville aux favoris
          favoritesData[cityId] = newFavorite;
        }

        // Mise à jour de Firestore
        setDoc(favoritesDocRef, favoritesData)
          .then(() => {
            console.log("Mise à jour réussie dans la base de données");
            // Mettre à jour l'état local après la mise à jour Firestore
            setFavorites(Object.values(favoritesData));
            setFavoriteCities(Object.values(favoritesData).map(fav => fav.city));
          })
          .catch((error) => {
            console.error("Erreur lors de la mise à jour dans la base de données :", error);
          });
      });
    } else {
      console.log("Non authentifié");
      navigate('/myprofile');
    }
  };


  // Vérifier si la ville est déjà définie comme favorite
  const isFavoriteCity = (city) => {
    return favoriteCities.includes(city);
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCityName = localStorage.getItem('cityName');

      if (city !== newCityName) {
        setCity(newCityName);
      }
    }, 1000); // Vérifie chaque seconde

    return () => {
      clearInterval(intervalId); // nettoie l'intervalle lors du démontage du composant
    };
  }, [city]);

  function limitWords(text, limit) {
    var words = text.split(' ');
    if (words.length > limit) {
      words.splice(limit);
      text = words.join(' ') + "...";
    }
    return text;
  }


  return (
    <div className='FlexClass' style={{ justifyContent: 'space-between' }} >

      <div className="location-component" >


        {
          !city ? (
            <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
          ) : (

            <h1 style={{ fontSize: '26px' }}>
              {t(titleKey)} - {limitWords(city, 4)}
            </h1>

          )
        }

        {isFavoriteCity(city) ? (
          <svg
            onClick={handleStarClick}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15 23.0625L9.18748 26.5625C8.97914 26.6875 8.77081 26.7448 8.56248 26.7344C8.35414 26.724 8.16664 26.6562 7.99998 26.5312C7.83331 26.4062 7.70831 26.25 7.62498 26.0625C7.54164 25.875 7.52081 25.6667 7.56248 25.4375L9.12498 18.7812L3.96873 14.3125C3.78123 14.1458 3.66664 13.9635 3.62498 13.7656C3.58331 13.5677 3.59373 13.3646 3.65623 13.1562C3.69789 12.9687 3.80206 12.8073 3.96873 12.6719C4.13539 12.5365 4.33331 12.4583 4.56248 12.4375L11.375 11.8438L14 5.59375C14.1041 5.36458 14.25 5.19792 14.4375 5.09375C14.625 4.98958 14.8125 4.9375 15 4.9375C15.1875 4.9375 15.375 4.98958 15.5625 5.09375C15.75 5.19792 15.8958 5.36458 16 5.59375L18.625 11.8438L25.4375 12.4375C25.6666 12.4583 25.8646 12.5365 26.0312 12.6719C26.1979 12.8073 26.3021 12.9687 26.3437 13.1562C26.4062 13.3646 26.4166 13.5677 26.375 13.7656C26.3333 13.9635 26.2187 14.1458 26.0312 14.3125L20.875 18.7812L22.4375 25.4375C22.4791 25.6667 22.4583 25.875 22.375 26.0625C22.2916 26.25 22.1666 26.4062 22 26.5312C21.8333 26.6562 21.6458 26.724 21.4375 26.7344C21.2291 26.7448 21.0208 26.6875 20.8125 26.5625L15 23.0625Z" fill="#FFC960" />
          </svg>
        ) : (
          <svg
            onClick={handleStarClick}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10.1875 23.4375L15 20.5625L19.8125 23.4687L18.5 18.0313L22.7187 14.375L17.1562 13.875L15 8.75L12.8437 13.8438L7.28123 14.3438L11.5 18L10.1875 23.4375ZM15 23.0625L9.18748 26.5625C8.97914 26.6875 8.77081 26.7448 8.56248 26.7344C8.35414 26.724 8.16664 26.6562 7.99998 26.5312C7.83331 26.4062 7.70831 26.25 7.62498 26.0625C7.54164 25.875 7.52081 25.6667 7.56248 25.4375L9.12498 18.7812L3.96873 14.3125C3.78123 14.1458 3.66664 13.9635 3.62498 13.7656C3.58331 13.5677 3.59373 13.3646 3.65623 13.1562C3.69789 12.9687 3.80206 12.8073 3.96873 12.6719C4.13539 12.5365 4.33331 12.4583 4.56248 12.4375L11.375 11.8438L14 5.59375C14.1041 5.36458 14.25 5.19792 14.4375 5.09375C14.625 4.98958 14.8125 4.9375 15 4.9375C15.1875 4.9375 15.375 4.98958 15.5625 5.09375C15.75 5.19792 15.8958 5.36458 16 5.59375L18.625 11.8438L25.4375 12.4375C25.6666 12.4583 25.8646 12.5365 26.0312 12.6719C26.1979 12.8073 26.3021 12.9687 26.3437 13.1562C26.4062 13.3646 26.4166 13.5677 26.375 13.7656C26.3333 13.9635 26.2187 14.1458 26.0312 14.3125L20.875 18.7812L22.4375 25.4375C22.4791 25.6667 22.4583 25.875 22.375 26.0625C22.2916 26.25 22.1666 26.4062 22 26.5312C21.8333 26.6562 21.6458 26.724 21.4375 26.7344C21.2291 26.7448 21.0208 26.6875 20.8125 26.5625L15 23.0625Z" fill="var(--white)" />
          </svg>

        )}
      </div>

      {lastUpdated && <p style={{ margin: '0' }} >Last update : {lastUpdated}</p>}


    </div>
  );
};
export default LocationComponent;