// Tab1.js
import React from 'react';
import TonightComponent from './TonightComponent';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';
import PlanetComponent from './PlanetComponent';
import HourForecast from './HourForecast';


const Tab1 = () => {



  return (
    <div>



      <LocationComponent />

      <div style={{ marginTop: '6px' }} >
        <ApiComponent>

          {({ openMeteoData }) => (
            <TonightComponent openMeteoData={openMeteoData} />
          )}
        </ApiComponent>

        <PlanetComponent />

        <ApiComponent>
          {({ openMeteoData }) => (
            <HourForecast openMeteoData={openMeteoData} />
          )}
        </ApiComponent>




      </div>



    </div>
  );
};

export default Tab1;