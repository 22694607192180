// Tab2.js
import React from 'react';


import DailyComponent from './DailyComponent';
import DailyComponentFree from './DailyComponentFree';
import ApiComponent from './ApiComponent';
import LocationComponent from './LocationComponent';
import StripeProducts from './StripeProducts';
import { useTranslation } from 'react-i18next';

const Tab3 = ({ user, subscription }) => {
  const { t } = useTranslation();

  console.log(subscription)
  return (
    <div>


      <LocationComponent />

      <div style={{ marginTop: '6px' }} ></div>
      <ApiComponent>
        {({ openMeteoData }) => (
          subscription === 1 ? (
            <DailyComponent openMeteoData={openMeteoData} />
          ) : (
            <>
            
              <DailyComponentFree openMeteoData={openMeteoData} />
         
         
            </>
          )
        )}
      </ApiComponent>



    </div>
  );
};

export default Tab3;