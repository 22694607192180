import React, { useState, useEffect } from 'react';
import { MoonPhase, Illumination, SearchRiseSet, Observer } from 'astronomy-engine';
import ArrowUp from '../assets/arrow_up.svg';
import ArrowDown from '../assets/arrow_down.svg'

import MercuryIcon from '../assets/mercury.svg';
import VenusIcon from '../assets/venus.svg';
import MarsIcon from '../assets/mars.svg';
import JupiterIcon from '../assets/jupiter.svg'
import SaturnIcon from '../assets/saturn.svg'
import UranusIcon from '../assets/uranus.svg'
import NeptuneIcon from '../assets/neptune.svg'

import waning_gibbous from '../assets/moonphase/waning_gibbous.png';
import third_quarter from '../assets/moonphase/third_quarter.png';
import waxing_gibbous from '../assets/moonphase/waxing_gibbous.png';
import first_quarter from '../assets/moonphase/first_quarter.png';
import waxing_crescent from '../assets/moonphase/waxing_crescent.png';
import waning_crescent from '../assets/moonphase/waning_crescent.png';
import new_moon from '../assets/moonphase/new_moon.png';
import full_moon from '../assets/moonphase/full_moon.png'
import { useTranslation } from 'react-i18next';


const SolarSystemComponent = () => {

    const { t } = useTranslation();

    const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')) || 47);
    const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')) || -2.2);
    const celestialBodies = ['Moon', 'Mercury', 'Venus', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'];
    const [celestialData, setCelestialData] = useState({});

    useEffect(() => {
        const date = new Date();
        const observer = new Observer(latitude, longitude, 0);

        const directionRise = 1;
        const directionSet = -1;
        const dateStart = date;
        const limitDays = 7;
        const metersAboveGround = 0;

        celestialBodies.forEach(async (body) => {
            let bodyData = {};

            if (body === 'Moon') {
                const eclipticLongitude = MoonPhase(date);
                bodyData.phase = calculateMoonPhase(eclipticLongitude);
                const illuminationResult = Illumination(body, date);
                bodyData.illumination = illuminationResult || { phase_fraction: 0 };
            }

            const riseSetResult = await SearchRiseSet(body, observer, directionRise, dateStart, limitDays, metersAboveGround);
            bodyData.riseInfo = riseSetResult || {};

            const setSetResult = await SearchRiseSet(body, observer, directionSet, dateStart, limitDays, metersAboveGround);
            bodyData.setInfo = setSetResult || {};

            setCelestialData(prevData => ({ ...prevData, [body]: bodyData }));
        });
    }, [latitude, longitude]);



    const calculateMoonPhase = (eclipticLongitude) => {
        const calculateMoonPhaseName = (eclipticLongitude) => {
            const normalizedLongitude = (eclipticLongitude + 360) % 360;
            let moonPhaseKey;

            if (normalizedLongitude < 22.5 || normalizedLongitude > 337.5) {
                moonPhaseKey = 'moonPhase.newMoon';
            } else if (normalizedLongitude < 67.5) {
                moonPhaseKey = 'moonPhase.waxingCrescent';
            } else if (normalizedLongitude < 112.5) {
                moonPhaseKey = 'moonPhase.firstQuarter';
            } else if (normalizedLongitude < 157.5) {
                moonPhaseKey = 'moonPhase.waxingGibbous';
            } else if (normalizedLongitude < 202.5) {
                moonPhaseKey = 'moonPhase.fullMoon';
            } else if (normalizedLongitude < 247.5) {
                moonPhaseKey = 'moonPhase.waningGibbous';
            } else if (normalizedLongitude < 292.5) {
                moonPhaseKey = 'moonPhase.lastQuarter';
            } else {
                moonPhaseKey = 'moonPhase.waningCrescent';
            }

            return moonPhaseKey;
        };


        const getMoonPhaseImage = (moonPhaseKey) => {
            const moonPhaseToImageMap = {
                'moonPhase.newMoon': new_moon,
                'moonPhase.waxingCrescent': waxing_crescent,
                'moonPhase.firstQuarter': first_quarter,
                'moonPhase.waxingGibbous': waxing_gibbous,
                'moonPhase.fullMoon': full_moon,
                'moonPhase.waningGibbous': waning_gibbous,
                'moonPhase.lastQuarter': third_quarter,
                'moonPhase.waningCrescent': waning_crescent,
            };

            return moonPhaseToImageMap[moonPhaseKey];
        };


        let moonPhaseName = calculateMoonPhaseName(eclipticLongitude);
        let moonPhaseImage = getMoonPhaseImage(moonPhaseName);

        return {
            moonPhaseName: moonPhaseName,
            moonPhaseImage: moonPhaseImage,
        };
    };





    const isNextDay = (time) => {
        const currentDay = new Date();
        currentDay.setHours(0, 0, 0, 0);

        const nextDay = new Date(currentDay);
        nextDay.setDate(currentDay.getDate() + 1);

        const timeDate = new Date(time);
        return timeDate >= nextDay;
    };

    return (
        <div>
            <div className='ContainerPlanet' >
                <div className='FlexClass' style={{ gap: '5px', position: 'absolute' }} >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6707 1.28232C14.3097 0.906228 13.753 0.861097 13.0159 1.10172C12.685 1.20708 12.3239 1.38758 11.9178 1.61319C11.662 1.74856 11.4063 1.91403 11.1204 2.09464C11.0452 2.13976 10.97 2.2 10.8948 2.24512C11.3161 2.45573 11.7071 2.72657 12.0832 3.02742C12.1735 2.96729 12.2487 2.92207 12.324 2.87694C13.016 2.45572 13.4673 2.30525 13.693 2.26012C13.6328 2.57609 13.362 3.26805 12.5346 4.47155C12.4744 4.5618 12.3992 4.66716 12.324 4.75741C12.1735 4.54681 11.993 4.33619 11.7974 4.15572C9.69131 2.04964 6.26127 2.04964 4.15515 4.15572C2.04907 6.26179 2.04907 9.69182 4.15515 11.7979C4.35075 11.9935 4.54626 12.159 4.75684 12.3245C4.65149 12.3998 4.56124 12.4599 4.47099 12.5351C3.26746 13.3626 2.5755 13.6333 2.25955 13.6935C2.28967 13.4979 2.41003 13.167 2.68078 12.6706C2.77102 12.4901 2.89138 12.2945 3.02676 12.0839C2.72591 11.7228 2.45507 11.3167 2.24445 10.8955C2.19933 10.9707 2.1391 11.046 2.09397 11.1211C1.83824 11.5122 1.62764 11.8583 1.46214 12.1893C1.31166 12.4901 1.1914 12.761 1.10106 13.0167C0.860544 13.7536 0.905667 14.3103 1.28176 14.6713C1.64284 15.0323 2.19943 15.0925 2.95162 14.8367C3.4782 14.6563 4.11002 14.3253 4.84711 13.8438C6.30635 12.881 8.05143 11.4218 9.73633 9.72184C11.4363 8.02187 12.8955 6.29186 13.8583 4.83262C14.3398 4.09544 14.6706 3.46361 14.8512 2.93713C15.0919 2.20004 15.0467 1.64336 14.6706 1.28237L14.6707 1.28232ZM4.14006 8.48828C3.8542 8.48828 3.6286 8.26267 3.6286 7.97682C3.6286 6.80341 4.07994 5.70524 4.90737 4.87781C5.10297 4.68221 5.43396 4.68221 5.62944 4.87781C5.82504 5.07341 5.82504 5.40439 5.62944 5.59988C4.99761 6.23172 4.65165 7.07414 4.65165 7.9768C4.65155 8.26266 4.41093 8.48826 4.14008 8.48826L4.14006 8.48828ZM13.3318 7.25475C13.5424 8.86449 13.0309 10.5644 11.7973 11.798C10.5638 13.0315 8.86377 13.543 7.25409 13.3323C8.29214 12.5049 9.37529 11.5271 10.4434 10.4439C11.5265 9.37579 12.5043 8.29274 13.3318 7.25467L13.3318 7.25475Z" fill="var(--grey10)" />
                    </svg>

                    <h2>{t('Planets')}</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '40px', paddingBlockStart: '24px' }} >
                    <div  >
                        <div>
                            {celestialData['Moon'] && (
                                <>
                                    <img style={{ width: '50px' }} src={celestialData['Moon'].phase.moonPhaseImage} alt="Moon phase" />
                                </>
                            )}
                        </div>


                        <div>
                            {celestialData['Moon'] && (
                                <>
                                    <p>
                                        {t('moonphasetitle')} <span style={{ color: 'var(--white)' }}>
                                            {t(celestialData['Moon'].phase.moonPhaseName)}
                                        </span>
                                    </p>
                                    <p>{t('illuminationtitle')} <span style={{ color: 'var(--white)' }}>{(celestialData['Moon'].illumination.phase_fraction * 100).toFixed(1)}%</span></p>
                                    {celestialData['Moon'].riseInfo.date && (
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <img src={ArrowUp} alt="Moonrise" />

                                            <p className='HourSetRise' >{new Date(celestialData['Moon'].riseInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData['Moon'].riseInfo.date) && '*'}</p>
                                        </div>
                                    )}
                                    {celestialData['Moon'].setInfo.date && (
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <img src={ArrowDown} alt="Moonset" />
                                            <p className='HourSetRise'  >{new Date(celestialData['Moon'].setInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData['Moon'].setInfo.date) && '*'}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>





                    {celestialBodies.filter(body => body !== 'Moon').map((body) => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2' }} key={body}>
                            <img src={getPlanetIcon(body)} alt={body} />

                            <p style={{ marginTop: '5px' }} >{body}</p>
                            <div style={{ textAlign: 'left' }} >

                                {celestialData[body] && (
                                    <>
                                        {celestialData[body].riseInfo.date && (
                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                <img src={ArrowUp} alt="Moonrise" />

                                                <p className='HourSetRise' >{new Date(celestialData[body].riseInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData[body].riseInfo.date) && '*'}</p>
                                            </div>
                                        )}
                                        {celestialData[body].setInfo.date && (
                                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                                <img src={ArrowDown} alt="Moonset" />
                                                <p className='HourSetRise' >{new Date(celestialData[body].setInfo.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}{isNextDay(celestialData[body].setInfo.date) && '*'}</p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>
                    ))}
                </div>


                <p style={{ marginBottom: '0' }} >{t('infoplanets')}</p>
            </div>

        </div>
    );
};


const getPlanetIcon = (planet) => {
    switch (planet) {
        case 'Mercury':
            return MercuryIcon;
        case 'Venus':
            return VenusIcon;
        case 'Mars':
            return MarsIcon;
        case 'Jupiter':
            return JupiterIcon;
        case 'Saturn':
            return SaturnIcon;
        case 'Uranus':
            return UranusIcon;
        case 'Neptune':
            return NeptuneIcon;
        default:
            return null;
    }
};

export default SolarSystemComponent;
